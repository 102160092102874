// Default values for Boostrap init
$theme-colors: (
  "primary": #0631cc,
  "accent": #11b0ef,
);

$black: #000;

$box-shadow: 0 0.2rem 0.4rem rgba($black, 0.15);
$box-shadow-lg: 0 0.2rem 0.2rem rgba($black, 0.5);

@import "~bootstrap/scss/bootstrap.scss";
